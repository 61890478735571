import React from 'react';
import './footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Sai</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">About</a>
                    </li>

                    <li>
                        <a href="#services" className="footer__link">Services</a>
                    </li>

                    <li>
                        <a href="#skills" className="footer__link">Skills</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.linkedin.com/in/saiarava/" className="home__social-icon" target='_blank'rel="noreferrer" >
                        <i className="uil uil-linkedin"></i>
                    </a>

                    <a href="https://github.com/avkumar" className="home__social-icon" target='_blank' rel="noreferrer" >
                        <i className="uil uil-github"></i>
                    </a>

                    <a href="https://x.com/SaiKumarArava2" className="home__social-icon" rel="noreferrer"  target='_blank'>
                        <i className="uil uil-twitter"></i>
                    </a>
                </div>

                <span className="footer__copy">&#169; All rights reserved</span>
            </div>
        </footer>
    )
}

export default Footer