import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/saiarava/" className="home__social-icon" target='_blank'>
                <i className="uil uil-linkedin"></i>
            </a>

            <a href="https://github.com/avkumar" className="home__social-icon" target='_blank'>
                <i className="uil uil-github"></i>
            </a>

            <a href="https://x.com/SaiKumarArava2" className="home__social-icon" target='_blank'>
                <i className="uil uil-twitter"></i>
            </a>

        </div>
    )
}

export default Social